<template>
  <CCard>
    <CCardHeader>
      <strong>Company Report</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" align="left">
          <div class="form-group">
            <label class="form__label" style="vertical-align: text-bottom">{{
                $lang.report.filter.date
              }}</label> &nbsp;
            <template>
              <date-range-picker v-model="dateRange"

                                 :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                 :showWeekNumbers="showWeekNumbers"
                                 :showDropdowns="showDropdowns"
                                 :autoApply="autoApply"
                                 :timePicker="timePicker"
                                 :timePicker24Hour="timePicker24Hour"
              >
                <!--    header slot-->
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    date slot-->
                <template #date="data">
                  <span class="small">{{ data.date | dateCell }}</span>
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                  <div class="ranges">
                    <ul>
                      <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                        <b>{{ name }}</b> <small class="text-muted">{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small>
                      </li>
                    </ul>
                  </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                  <div>
                    <b class="text-black">Current Week (m/d/Y)</b> : {{ data.rangeText }}
                  </div>
                  <div style="margin-left: auto">
                    <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-primary btn-sm">Choose
                      Current</a>
                  </div>
                </div>
              </date-range-picker>
            </template>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.filter.candidates.filter.industry
              }}</label>
            <v-select
                :options="industryOptions"
                v-model="filter.industry"
                @option:selected="getSubIndustry"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.opportunity.filter.subIndustry
              }}</label>
            <v-select
                :options="services"
                multiple="multiple"
                v-model="filter.services"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.filter.companies.filter.size
              }}</label>
            <v-select
                :options="sizeOptions"
                multiple="multiple"
                v-model="filter.size"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.filter.companies.filter.status
              }}</label>
            <v-select
                :options="profileStatus"
                v-model="filter.status"
            ></v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.report.filter.countries
              }}</label>
            <v-select
                :options="CountryOptions"
                v-model="filter.country"
                @option:selected="loadCities"
            >
            </v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.filter.candidates.filter.cities
              }}</label>
            <v-select :options="cityOptions" v-model="filter.city">
            </v-select>
          </div>
        </CCol>
        <CCol sm="3">
          <div class="form-group" style="margin-top: 30px">
            <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="applyFilter()"
                v-c-tooltip="$lang.common.filter.button.filter"
            >
              {{ $lang.common.filter.button.filter }}
            </CButton>
            &nbsp;
            <CButton
                color="danger"
                size="sm"
                @click="resetFilter()"
                v-c-tooltip="$lang.common.filter.button.cancel"
            >
              {{ $lang.common.filter.button.cancel }}
            </CButton>
          </div>
        </CCol>
      </CRow>
      <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
      <highcharts class="hc" :options="accountStatusChartOptions" ref="accountStatus"></highcharts>
      <highcharts class="hc" :options="companiesIndividualChartOptions" ref="companiesIndividual"></highcharts>
      <highcharts class="hc" :options="industriesChartOptions" ref="industries"></highcharts>
      <highcharts class="hc" :options="servicesChartOptions" ref="services"></highcharts>
      <highcharts class="hc" :options="employeeSizeChartOptions" ref="employeeSize"></highcharts>
      <highcharts class="hc" :options="citiesChartOptions" ref="branches"></highcharts>
      <highcharts class="hc" :options="usersChartOptions" ref="users"></highcharts>




    </CCardBody>
  </CCard>
</template>
<style scoped>
.slot {
  background-color: #3c5de8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: white;
}
</style>
<script>
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";

import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);
import exportDataHC from "highcharts/modules/export-data";

exportDataHC(Highcharts);
Vue.use(HighchartsVue)
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  country,
  currencytype,
  industry,
  companysize,
  opportunity,
} from "../../store/url";

Vue.use(ServerTable, {}, false);
let url;
export default {
  name: "CompanyFilter",
  mixins: [Mixin],
  components: {
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)
    return {
      self: this,
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Number of Profiles'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      companiesIndividualChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Number of Company or Individual'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      accountStatusChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Profile Status'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      industriesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Industries'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      employeeSizeChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Employee Size'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      servicesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Services'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      citiesChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Cities ( Branches )'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      usersChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'No of Users'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      currencyChartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Currencies'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:.1f}%'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      singleDatePicker: 'range',
      min: [0, 1000000],
      max: [0, 1000000],
      number: 1000,
      appendToBody: true,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      timePicker: false,
      timePicker24Hour: false,
      dateRange: {startDate, endDate},
      industryOptions: [],
      services: [],
      sizeOptions: [],
      cityOptions: [],
      usersOptions: [],
      profileStatus: [],
      CountryOptions: [],
      currencyOptions: [],
      module: opportunity,
      moduleIndustry: industry,
      moduleCurrency: currencytype,
      countryModule: country,
      moduleSize: companysize,
      filter: {
        industry: "",
        services: "",
        country: "",
        size: "",
        status: "",
        city: "",
      },
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
  created() {
    let self = this;
    url = '/reports/companies',
        this.loadIndustry();
    this.countryList();
    this.loadSize();
    self.profileStatus.push(
        {value: "0", label: "Non Verified"},
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"},
        {value: "4", label: "Rejected"}
    );
  },
  methods: {
    getSubIndustry() {
      let self = this;
      if (self.filter.industry.value > 0) {
        const postData = {industry_id: self.filter.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.services = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.services.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },
    loadIndustry() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
        response.data.data.map(function (value, key) {
          if (value.industryId === "") {
            self.industryOptions.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    countryList() {
      let self = this;
      axios.get(this.listUrlApi(this.countryModule)).then((response) => {
        response.data.data.map(function (value, key) {
          self.CountryOptions.push({value: value.id, label: value.name});
          self.currencyOptions.push(
              {
                value: value.id,
                label: value.currencySymbol + ' (' + value.name + '-' + value.countryCode + ' )'
              });
        });
      });
    },
    loadCities() {
      let self = this;
      self.filter.city = '';
      axios
          .get('/cities/country-cities/' + self.filter.country.value)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadSize() {
      let self = this;
      self.filter.size = '';
      axios.get(this.listUrlApi(this.moduleSize)).then((response) => {
        self.sizeOptions = [];
        response.data.data.map(function (value, key) {
          self.sizeOptions.push({value: value.id, label: value.title});
        });
      });
    },

    loadCompanies(reqObj) {
      let self = this;
      axios.post(url + '/', reqObj)
          .then((response) => {
            store.commit("showLoader", false);
            self.chartOptions.series = response.data.data;
          });
    },
    registerReport(reqObj) {
      this.loadCompanies(reqObj);
      this.profiles(reqObj);
      this.accountStatus(reqObj);
      this.industries(reqObj);
      this.employeeSize(reqObj);
      this.companyServices(reqObj);
      this.cities(reqObj);
      this.users(reqObj);
    },

    profiles(reqObj) {
      let self = this;
      axios.post(url + '/profiles', reqObj)
          .then((response) => {
            self.companiesIndividualChartOptions.series = response.data.data ?? [];
          });
    },
    accountStatus(reqObj) {
      let self = this;
      axios.post(url + '/profile-status', reqObj)
          .then((response) => {
            self.accountStatusChartOptions.series = response.data.data ?? [];
          });
    },
    industries(reqObj) {
      let self = this;
      axios.post(url + '/industries', reqObj)
          .then((response) => {

            self.industriesChartOptions.series = response.data.data ?? [];
          });
    },
    employeeSize(reqObj) {
      let self = this;
      axios.post(url + '/employee-size', reqObj)
          .then((response) => {
            self.employeeSizeChartOptions.series = response.data.data ?? [];
          });
    },
    companyServices(reqObj) {
      let self = this;
      axios.post(url + '/services', reqObj)
          .then((response) => {
            self.servicesChartOptions.series = response.data.data ?? [];
          });
    },
    cities(reqObj) {
      let self = this;
      axios.post(url + '/cities', reqObj)
          .then((response) => {
            self.citiesChartOptions.series = response.data.data ?? [];
          });
    },
    users(reqObj) {
      let self = this;
      axios.post(url + '/users', reqObj)
          .then((response) => {
            self.usersChartOptions.series = response.data.data ?? [];
          });
    },
    applyFilter() {
      let self = this;
      let industry = "";
      let country, city, services, size, status = "";
      self.selectedServices = [];
      self.selectedSize = [];
      industry = this.checkNullValue(this.filter.industry) ? this.filter.industry.value : '';
      services = this.checkNullValue(this.filter.services) ? this.filter.services.value : '';
      size = this.checkNullValue(this.filter.size) ? this.filter.size.value : '';
      //currency = this.checkNullValue(this.filter.currency) ? this.filter.currency.value :'';
      country = this.checkNullValue(this.filter.country) ? this.filter.country.value : '';
      city = this.checkNullValue(this.filter.city) ? this.filter.city.value : '';
      status = this.checkNullValue(this.filter.status) ? this.filter.status.value : '';
      if (this.checkNullValue(this.filter.services)) {
        services = self.filter.services;
        services.map(function (value, key) {
          self.selectedServices.push(value.value);
        });
      }
      if (this.checkNullValue(this.filter.size)) {
        size = self.filter.size;
        size.map(function (value, key) {
          self.selectedSize.push(value.value);
        });
      }

      const reqObj = {
        dateRange: this.dateRange,
        industry: industry,
        //currency: currency,
        status: status,
        country: country,
        size: self.selectedSize,
        cities: city,
        services: self.selectedServices,
      };
      store.commit("showLoader", true);
      this.filterReport(reqObj);
    },
    resetFilter() {
      let self = this;
      this.$data.filter = [];
      self.services = [];
      this.filterReport();
    },
    filterReport(params) {
      if (!params) {
        params = {dateRange: this.dateRange};
      }
      this.registerReport(params);
      store.commit("showLoader", false);
    }
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    this.filterReport()
  },
};
</script>
<style>
div .error {
  color: red;
}


.hasError label {
  color: red;
}


.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

</style>
