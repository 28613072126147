<template>
  <company-filter></company-filter>
</template>

<script>
import store from "../../store/store";
import companyFilter from "./CompanyFilter";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "CompanyReport",
  mixins: [Mixin],
  components: {
    companyFilter
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {
    store.commit("showLoader", false); // Loader Off
  },
};
</script>

